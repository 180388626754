import styled from '@emotion/styled';
import React from 'react';
import { __t } from './i18n';
import Scroller from './Scroller';
import { Paper } from './styles';
import { paperBg } from './styles/Paper';
import { TypographyProps } from './styles/Text';
import { SpacingIndex } from './theme';

export interface Cell {
    width?: SpacingIndex;
    align?: TypographyProps['align'];
}

export interface Column extends Cell {
    title: string;
    field: string;
}

const StyledTable = styled.table(() => ({
  width: '100%',
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));

const Thead = styled.thead(({ theme }) => ({
  color: theme.palette.foreground[100][0],
  borderBottom: '1px solid',
}));

const TableHeadings = styled.th<Cell>(({ theme, align }) => ({
  padding: theme.spacing(2),
  textAlign: align,
  paddingTop: 0,
}));

const TableCell = styled.td<Cell>(({ theme, align }) => ({
  padding: theme.spacing(2),
  textAlign: align,
}));

interface RowProps {
    isActive: boolean;
}

const TableRow = styled.tr<RowProps>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.info[900][0] : 'inherit',
}));

interface TableProps {
    columns: Column[];
    rows: any[];
    scrollerFlag?: boolean
}

export default function Table({ columns, rows, scrollerFlag = false }: TableProps) {
  return (
    <Paper borderRadius="medium">
      {scrollerFlag && <CustomScroller style={{ overflowX: 'scroll' }}>
        <TableInner columns={columns} rows={rows}/>
      </CustomScroller>}
      {!scrollerFlag && <Scroller background={paperBg.main}>
        <TableInner columns={columns} rows={rows}/>
      </Scroller>}
    </Paper>
  );
}

const TableInner = ({ columns, rows }) => {
  return (
    <StyledTable>
      <Thead>
        <tr>
          {columns.map(({ title, align }) => (
            <TableHeadings align={align} key={title}>{__t(title)}</TableHeadings>
          ))}
        </tr>
      </Thead>
      <tbody>
        {rows.map((item, index) => (
          <TableRow isActive={item.active} key={index}>
            {columns.map(({ title, align, field }) => (
              <TableCell align={align} key={title}>
                {item[field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    </StyledTable>
  );
};

const CustomScroller = styled.div(() => ({
  overflowX: 'scroll',
  padding: '2rem',
  ['&::-webkit-scrollbar']: {
    height: '4px',
  },
  ['&::-webkit-scrollbar-track']: {
    background: '#0f0f0f',
    borderRadius: '1rem',
  },
  ['&::-webkit-scrollbar-thumb']: {
    background: '#888',
    borderRadius: '1rem',
  },
  ['&::-webkit-scrollbar-thumb:hover']: {
    background: '#555',
  },
}));
